import React, {
    useEffect,
    useContext,
    useState,
    useRef,
    useMemo,
    type FC,
} from 'react';

import {
    Box,
    Button,
    ButtonGroup,
    IconButton,
    type TablePaginationProps,
    useTheme,
    Pagination as MuiPagination,
    Paper,
    Avatar,
    Typography,
    Dialog,
    Slide,
    DialogContent,
    DialogActions,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch,
    Grid,
    Divider,
    Collapse,
    CardContent,
    Autocomplete,
    Chip,
    List,
    ListItemButton,
    Tooltip,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Card,
    Badge,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';

import Header from '@src/global/components/Header';

import { toast } from 'react-toastify';

import { getColors } from '@src/global/context/theme';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetRoleDataQuery } from '@src/slices/rolesApiSlice';

import {
    DragDropContext,
    Draggable,
    Droppable,
    type DropResult,
    type OnDragEndResponder,
} from 'react-beautiful-dnd';

interface Item {
    _id: string;
    primary: string;
    secondary: string;
    isDragDisabled?: boolean;
}

interface Props {
    items: Item[];
    onDragEnd: OnDragEndResponder;
    // colors: any;
}

interface ItemProps {
    item: any;
    index: number;
    // colors: any;
}

const bindings = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const getRoleData = useGetRoleDataQuery({});
    const { rolesData } = useSelector((state: any) => state.roles);
    const { userInfo } = useSelector((state: any) => state.auth);

    const [scopes, setScopes] = useState([]) as any;
    const [highestRole, setHighestRole] = useState(null) as any;
    const [selectedRole, setSelectedRole] = useState(null) as any;
    const [selectedPermission, setSelectedPermission] = useState(null) as any;

    const [roleBindings, setRoleBindings] = React.useState([
        // {
        //     id: '1',
        //     primary: 'Item 1',
        //     secondary: 'Secondary',
        //     isDragDisabled: true,
        // },
        // {
        //     id: '2',
        //     primary: 'Item 2',
        //     secondary: 'Secondary',
        // },
        // {
        //     id: '3',
        //     primary: 'Item 3',
        //     secondary: 'Secondary',
        // },
    ] as any[]);

    if (getRoleData.error) {
        console.error(
            'Could not get Role Data: ',
            (getRoleData.error as { error: string; status: string }).error,
        );
    }

    useEffect(() => {
        // console.log(highestRole);
        console.log(scopes);
    }, [highestRole, scopes]);

    useEffect(() => {
        setHighestRole(determineHighestRole());
        setScopes(mapScopes());
    }, [rolesData]);

    useEffect(() => {
        saturateRoles();
    }, [location.pathname]);

    const saturateRoles = (doToast: boolean = false): void => {
        if (doToast) {
            toast(
                (t) => (
                    <span
                        onClick={() => {
                            toast.dismiss(t.toastProps.toastId);
                        }}
                    >
                        Successfully reloaded table.
                    </span>
                ),
                { icon: <>✅</>, type: 'success' },
            );
        }

        void getRoleData
            .refetch()
            .unwrap()
            .then((newData: any) => {
                if (newData?.success) {
                    dispatch({
                        type: 'roles/setRolesData',
                        payload: {
                            key: 'roles',
                            data: newData.roles,
                        },
                    });
                }
            });
    };

    const determineHighestRole = (): any => {
        if (rolesData?.length > 0) {
            const _userRoles = rolesData.filter((role: any) =>
                userInfo?.roles?.includes(role.role),
            );
            const _highestRole = _userRoles.reduce((prev: any, current: any) =>
                prev.weight < current.weight ? prev : current,
            );
            return _highestRole;
        }
    };

    const mapScopes = (): any => {
        if (rolesData?.length > 0) {
            const _scopes = rolesData
                ?.map((role: any) => role.scope)
                .reduce((acc: any, val: any) => {
                    if (acc.includes(val)) {
                        return acc;
                    }
                    return acc.concat(val);
                }, []);
            return _scopes;
        }
    };

    const reorder = (
        list: any[],
        startIndex: number,
        endIndex: number,
    ): any[] => {
        const result = Array.from(list);
        // result[startIndex] = { ...result[startIndex], weight: endIndex };
        // result[endIndex] = { ...result[endIndex], weight: startIndex };
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        const newResult = result.map((item, index) => {
            if (item.weight === index) {
                const { newWeight, ...rest } = item;
                return rest;
            }

            return { ...item, newWeight: index };
        });

        return newResult;
    };

    const onDragEndRoles = ({ destination, source }: DropResult): void => {
        // dropped outside the list
        if (!destination) return;

        const newItems = reorder(
            rolesData as any[],
            source.index,
            destination.index,
        );

        // setRoleBindings(newItems);
        dispatch({
            type: 'roles/setRolesData',
            payload: {
                key: 'roles',
                data: newItems,
            },
        });
        console.log(newItems);
    };

    const DraggableListItem: FC<ItemProps> = ({ item, index }) => {
        return (
            <Draggable
                draggableId={item._id}
                index={index}
                // isDragDisabled={item.isDragDisabled}
            >
                {(provided: any, snapshot: any) => (
                    <Tooltip title="Drag Me!">
                        <ListItemButton
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item._id}
                            onClick={() => {
                                setSelectedRole(item);
                                console.log(
                                    item,
                                    item.role === selectedRole?.role,
                                );
                            }}
                            selected={item.role === selectedRole?.role}
                            sx={[
                                {
                                    marginRight: '1vw',
                                },
                                item.role === selectedRole?.role && {
                                    backgroundColor: `${colors.blueAccent[400]} !important`,
                                },
                                snapshot.isDragging
                                    ? { background: colors.blueAccent[400] }
                                    : {},
                            ]}
                        >
                            <Box
                                key={index}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '2px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {userInfo?.roles?.includes(item.role) ? (
                                        <Badge
                                            badgeContent="⭐"
                                            color="success"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Chip
                                                label={
                                                    item.newWeight
                                                        ? item.newWeight
                                                        : item.weight
                                                }
                                                variant="outlined"
                                                sx={{
                                                    backgroundColor: `${colors.primary[400]}`,
                                                }}
                                            />
                                        </Badge>
                                    ) : (
                                        <Chip
                                            label={
                                                item.newWeight
                                                    ? item.newWeight
                                                    : item.weight
                                            }
                                            variant="outlined"
                                            sx={{
                                                backgroundColor: `${colors.primary[400]}`,
                                            }}
                                        />
                                    )}
                                    <Typography variant="h4" ml="1vw">
                                        {item.role}
                                    </Typography>
                                </Box>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: colors.redAccent[400],
                                        height: '2vh',
                                        width: '4rem',
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        // TODO: Delete Role
                                        console.log(item, 'HI');
                                    }}
                                >
                                    <Tooltip title="Delete">
                                        <DeleteIcon />
                                    </Tooltip>
                                </Button>
                            </Box>
                        </ListItemButton>
                    </Tooltip>
                )}
            </Draggable>
        );
    };

    // eslint-disable-next-line react/display-name
    const DraggableList: FC<Props> = React.memo(
        // eslint-disable-next-line react/prop-types
        ({ items, onDragEnd: dragEnded }) => {
            return (
                <DragDropContext onDragEnd={dragEnded}>
                    <Droppable droppableId="droppable-list">
                        {(provided: any) => (
                            <List
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {/* eslint-disable-next-line react/prop-types */}
                                {items.map((item: any, index: number) => (
                                    <DraggableListItem
                                        item={item}
                                        index={
                                            item.newWeight
                                                ? item.newWeight
                                                : item.weight
                                        }
                                        key={item._id + '_' + index}
                                    />
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            );
        },
    );

    function CustomNoDataOverlay(): JSX.Element {
        const customOverlayTemplate = (props: any): JSX.Element => {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        // zIndex: 2,
                        '& .ant-empty-img-1': {
                            fill:
                                theme.palette.mode === 'light'
                                    ? colors.blueAccent[700]
                                    : colors.blueAccent[300],
                        },
                        '& .ant-empty-img-2': {
                            fill:
                                theme.palette.mode === 'light'
                                    ? colors.blueAccent[700]
                                    : colors.blueAccent[300],
                        },
                        '& .ant-empty-img-3': {
                            fill:
                                theme.palette.mode === 'light'
                                    ? colors.blueAccent[600]
                                    : colors.blueAccent[500],
                        },
                        '& .ant-empty-img-4': {
                            fill:
                                theme.palette.mode === 'light'
                                    ? colors.blueAccent[800]
                                    : colors.blueAccent[200],
                        },
                        '& .ant-empty-img-5': {
                            fillOpacity:
                                theme.palette.mode === 'light' ? '0.8' : '0.2',
                            fill:
                                theme.palette.mode === 'light'
                                    ? '#f5f5f5'
                                    : '#fff',
                        },
                    }}
                >
                    <svg
                        width="120"
                        height="100"
                        viewBox="0 0 184 152"
                        aria-hidden
                        focusable="false"
                    >
                        <g fill="none" fillRule="evenodd">
                            <g transform="translate(24 31.67)">
                                <ellipse
                                    className="ant-empty-img-5"
                                    cx="67.797"
                                    cy="106.89"
                                    rx="67.797"
                                    ry="12.668"
                                />
                                <path
                                    className="ant-empty-img-1"
                                    d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                />
                                <path
                                    className="ant-empty-img-2"
                                    d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                />
                                <path
                                    className="ant-empty-img-3"
                                    d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                />
                            </g>
                            <path
                                className="ant-empty-img-3"
                                d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                            />
                            <g
                                className="ant-empty-img-4"
                                transform="translate(149.65 15.383)"
                            >
                                <ellipse
                                    cx="20.654"
                                    cy="3.167"
                                    rx="2.849"
                                    ry="2.815"
                                />
                                <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                            </g>
                        </g>
                    </svg>
                    {props}
                </Box>
            );
        };

        return customOverlayTemplate(
            <>
                <Box sx={{ mt: 1 }}>No Data</Box>
                <pre>(data=&#123;[]&#125;)</pre>
            </>,
        );
    }

    return (
        <>
            <Box m="20px">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: selectedRole ? 'space-between' : 'left',
                    }}
                >
                    <Header title="Bindings" subtitle="Role Bindings" />
                    {selectedRole && (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: colors.blueAccent[400],
                                height: 'auto',
                                width: '12rem',
                                ml: 'auto',
                                mr: '2vw',
                            }}
                            onClick={() => {}}
                        >
                            Role: {selectedRole.role}
                        </Button>
                    )}
                </Box>

                <Box m="10px 0 0 0" height="70vh">
                    <Grid container spacing={4} alignItems={'center'}>
                        <Grid item xs={5}>
                            <Paper
                                sx={{
                                    backgroundColor: colors.primary[400],
                                }}
                            >
                                <Box p="20px">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="h4">
                                            Roles
                                        </Typography>
                                        <Box
                                            sx={{
                                                ml: 'auto',
                                                mr: '1vw',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor:
                                                        colors.greenAccent[600],
                                                    height: '3vh',
                                                    width: '8rem',
                                                }}
                                                startIcon={<AddIcon />}
                                                onClick={() => {}}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                    onClick={() => {
                                                        // TODO: Add Role
                                                    }}
                                                >
                                                    Add Role
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Box>

                                    <br />
                                    <Divider />
                                    <List
                                        sx={{
                                            overflow: 'auto',
                                            maxHeight: '20vh',
                                            '&::-webkit-scrollbar': {
                                                width: '0.6vw',
                                            },
                                            '&::-webkit-scrollbar-button': {
                                                display: 'none !important',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                WebkitBoxShadow:
                                                    'inset 0 0 3px rgba(0,0,0,0.3) !important',
                                                WebkitBorderRadius:
                                                    '10px !important',
                                                BorderRadius: '10px !important',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                WebkitBorderRadius:
                                                    '10px !important',
                                                BorderRadius: '10px !important',
                                                background: `${colors.blueAccent[400]} !important`,
                                                transition:
                                                    'background-color 3s ease !important',
                                                '&:hover': {
                                                    background: `${colors.blueAccent[300]} !important`,
                                                },
                                            },
                                            '.Mui-selected': {
                                                backgroundColor:
                                                    colors.blueAccent[400],
                                            },
                                        }}
                                    >
                                        {scopes?.map(
                                            (scope: any, index: number) => {
                                                return (
                                                    <>
                                                        <ListItem
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Chip
                                                                variant="filled"
                                                                label={
                                                                    'Scope: ' +
                                                                    scope
                                                                }
                                                                sx={{
                                                                    backgroundColor: `${colors.primary[400]}`,
                                                                }}
                                                            />
                                                        </ListItem>
                                                        {rolesData
                                                            ?.filter(
                                                                (role: any) =>
                                                                    role?.weight <=
                                                                        highestRole?.weight &&
                                                                    role.scope ===
                                                                        scope,
                                                            )
                                                            .map(
                                                                (
                                                                    role: any,
                                                                    _index: number,
                                                                ) => (
                                                                    <ListItemButton
                                                                        key={
                                                                            role._id
                                                                        }
                                                                        onClick={() => {
                                                                            setSelectedRole(
                                                                                role,
                                                                            );
                                                                            console.log(
                                                                                role,
                                                                                role.role ===
                                                                                    selectedRole?.role,
                                                                            );
                                                                        }}
                                                                        selected={
                                                                            role.role ===
                                                                            selectedRole?.role
                                                                        }
                                                                        sx={[
                                                                            {
                                                                                marginRight:
                                                                                    '1vw',
                                                                            },
                                                                            role.role ===
                                                                                selectedRole?.role && {
                                                                                backgroundColor: `${colors.blueAccent[400]} !important`,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Box
                                                                            key={
                                                                                _index
                                                                            }
                                                                            sx={{
                                                                                width: '100%',
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'space-between',
                                                                                padding:
                                                                                    '2px',
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                    flexDirection:
                                                                                        'row',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    justifyContent:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                {userInfo?.roles?.includes(
                                                                                    role.role,
                                                                                ) ? (
                                                                                    <Badge
                                                                                        badgeContent="⭐"
                                                                                        color="success"
                                                                                        anchorOrigin={{
                                                                                            vertical:
                                                                                                'top',
                                                                                            horizontal:
                                                                                                'left',
                                                                                        }}
                                                                                    >
                                                                                        <Chip
                                                                                            label={
                                                                                                role.weight
                                                                                            }
                                                                                            variant="outlined"
                                                                                            sx={{
                                                                                                backgroundColor: `${colors.primary[400]}`,
                                                                                            }}
                                                                                        />
                                                                                    </Badge>
                                                                                ) : (
                                                                                    <Chip
                                                                                        label={
                                                                                            role.weight
                                                                                        }
                                                                                        variant="outlined"
                                                                                        sx={{
                                                                                            backgroundColor: `${colors.primary[400]}`,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                <Typography
                                                                                    variant="h4"
                                                                                    ml="1vw"
                                                                                >
                                                                                    {
                                                                                        role.role
                                                                                    }
                                                                                </Typography>
                                                                            </Box>
                                                                            <Button
                                                                                variant="contained"
                                                                                sx={{
                                                                                    backgroundColor:
                                                                                        colors
                                                                                            .redAccent[400],
                                                                                    height: '2vh',
                                                                                    width: '4rem',
                                                                                }}
                                                                                onClick={(
                                                                                    event,
                                                                                ) => {
                                                                                    event.stopPropagation();
                                                                                    // TODO: Delete Role
                                                                                    console.log(
                                                                                        role,
                                                                                        'HI',
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <Tooltip title="Delete">
                                                                                    <DeleteIcon />
                                                                                </Tooltip>
                                                                            </Button>
                                                                        </Box>
                                                                    </ListItemButton>
                                                                ),
                                                            )}

                                                        {rolesData?.length >
                                                            highestRole?.weight && (
                                                            <DraggableList
                                                                items={rolesData.filter(
                                                                    (
                                                                        role: any,
                                                                    ) =>
                                                                        role.weight >
                                                                            highestRole?.weight &&
                                                                        role.scope ===
                                                                            scope,
                                                                )}
                                                                onDragEnd={
                                                                    onDragEndRoles
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                );
                                            },
                                        )}

                                        {/* {rolesData
                                            ?.filter(
                                                (role: any) =>
                                                    role?.weight <=
                                                    highestRole?.weight,
                                            )
                                            .map((role: any, index: number) => (
                                                <ListItemButton
                                                    key={role._id}
                                                    onClick={() => {
                                                        setSelectedRole(role);
                                                        console.log(
                                                            role,
                                                            role.role ===
                                                                selectedRole?.role,
                                                        );
                                                    }}
                                                    selected={
                                                        role.role ===
                                                        selectedRole?.role
                                                    }
                                                    sx={[
                                                        {
                                                            marginRight: '1vw',
                                                        },
                                                        role.role ===
                                                            selectedRole?.role && {
                                                            backgroundColor: `${colors.blueAccent[400]} !important`,
                                                        },
                                                    ]}
                                                >
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'space-between',
                                                            padding: '2px',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            {userInfo?.roles?.includes(
                                                                role.role,
                                                            ) ? (
                                                                <Badge
                                                                    badgeContent="⭐"
                                                                    color="success"
                                                                    anchorOrigin={{
                                                                        vertical:
                                                                            'top',
                                                                        horizontal:
                                                                            'left',
                                                                    }}
                                                                >
                                                                    <Chip
                                                                        label={
                                                                            role.weight
                                                                        }
                                                                        variant="outlined"
                                                                        sx={{
                                                                            backgroundColor: `${colors.primary[400]}`,
                                                                        }}
                                                                    />
                                                                </Badge>
                                                            ) : (
                                                                <Chip
                                                                    label={
                                                                        role.weight
                                                                    }
                                                                    variant="outlined"
                                                                    sx={{
                                                                        backgroundColor: `${colors.primary[400]}`,
                                                                    }}
                                                                />
                                                            )}
                                                            <Typography
                                                                variant="h4"
                                                                ml="1vw"
                                                            >
                                                                {role.role}
                                                            </Typography>
                                                        </Box>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor:
                                                                    colors
                                                                        .redAccent[400],
                                                                height: '2vh',
                                                                width: '4rem',
                                                            }}
                                                            onClick={(
                                                                event,
                                                            ) => {
                                                                event.stopPropagation();
                                                                // TODO: Delete Role
                                                                console.log(
                                                                    role,
                                                                    'HI',
                                                                );
                                                            }}
                                                        >
                                                            <Tooltip title="Delete">
                                                                <DeleteIcon />
                                                            </Tooltip>
                                                        </Button>
                                                    </Box>
                                                </ListItemButton>
                                            ))}

                                        {rolesData?.length >
                                            highestRole?.weight && (
                                            <DraggableList
                                                items={rolesData.filter(
                                                    (role: any) =>
                                                        role.weight >
                                                        highestRole?.weight,
                                                )}
                                                onDragEnd={onDragEndRoles}
                                            />
                                        )} */}

                                        {rolesData?.length === 0 && (
                                            <CustomNoDataOverlay />
                                        )}
                                    </List>
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid
                            item
                            xs={1}
                            ml="auto"
                            textAlign={'center'}
                            sx={{
                                transition: 'color 0.5s',
                                '&:hover': {
                                    color: colors.greenAccent[600],
                                },
                            }}
                        >
                            <ArrowForwardIcon
                                sx={{
                                    fontSize: '5rem',
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Paper
                                sx={{
                                    backgroundColor: colors.primary[400],
                                }}
                            >
                                <Box p="20px">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="h4">
                                            Active Bindings
                                        </Typography>
                                        <Box
                                            sx={{
                                                ml: 'auto',
                                                mr: '1vw',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor:
                                                        colors.greenAccent[600],
                                                    height: '3vh',
                                                    width: '8rem',
                                                    mr: '1vw',
                                                }}
                                                startIcon={<PersonAddAltIcon />}
                                                onClick={() => {
                                                    // TODO: Bind User
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    Bind User
                                                </Box>
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor:
                                                        colors.greenAccent[600],
                                                    height: '3vh',
                                                    width: '8rem',
                                                }}
                                                startIcon={<GroupAddIcon />}
                                                onClick={() => {
                                                    // TODO: Bind Group
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    Bind Group
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Box>

                                    <br />
                                    <Divider />
                                    <br />
                                    {/* {roleBindings && roleBindings.length > 0 ? (
                                        <DraggableList
                                            items={roleBindings}
                                            onDragEnd={onDragEnd}
                                        />
                                    ) : ( */}
                                    <CustomNoDataOverlay />
                                    {/* )} */}
                                </Box>
                            </Paper>
                        </Grid>

                        <Grid item xs={4}>
                            <Paper
                                sx={{
                                    backgroundColor: colors.primary[400],
                                }}
                            >
                                <Box p="20px">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="h4">
                                            Assigned Permissions
                                        </Typography>
                                        <Box
                                            sx={{
                                                ml: 'auto',
                                                mr: '1vw',
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor:
                                                        colors.greenAccent[600],
                                                    height: '3vh',
                                                    width: 'auto',
                                                }}
                                                startIcon={<AddIcon />}
                                                onClick={() => {}}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                    onClick={() => {
                                                        // TODO: Add Permission
                                                    }}
                                                >
                                                    Add Permission
                                                </Box>
                                            </Button>
                                        </Box>
                                    </Box>

                                    <br />
                                    <Divider />
                                    <br />

                                    {selectedRole &&
                                    selectedRole.permissions?.length > 0 ? (
                                        <List
                                            sx={{
                                                overflow: 'auto',
                                                maxHeight: '20vh',
                                                '*::-webkit-scrollbar': {
                                                    width: '2em',
                                                },
                                                '*::-webkit-scrollbar-track': {
                                                    boxShadow:
                                                        'inset 0 0 6px rgba(0,0,0,0.00)',
                                                },
                                                '*::-webkit-scrollbar-thumb': {
                                                    backgroundColor:
                                                        'rgba(0,0,0,.1)',
                                                    outline:
                                                        '12px solid slategrey',
                                                },
                                                '.Mui-selected': {
                                                    backgroundColor:
                                                        colors.blueAccent[400],
                                                },
                                            }}
                                        >
                                            {selectedRole.permissions.map(
                                                (
                                                    permission: any,
                                                    index: number,
                                                ) => (
                                                    <ListItemButton
                                                        key={permission._id}
                                                        onClick={() => {
                                                            setSelectedPermission(
                                                                permission,
                                                            );
                                                            console.log(
                                                                permission,
                                                                permission ===
                                                                    selectedPermission,
                                                            );
                                                        }}
                                                        selected={
                                                            permission ===
                                                            selectedPermission
                                                        }
                                                        sx={[
                                                            {
                                                                marginRight:
                                                                    '1vw',
                                                            },
                                                            permission ===
                                                                selectedPermission && {
                                                                backgroundColor: `${colors.blueAccent[400]} !important`,
                                                            },
                                                        ]}
                                                    >
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'row',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'space-between',
                                                                padding: '2px',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h4"
                                                                    ml="1vw"
                                                                >
                                                                    {permission}
                                                                </Typography>
                                                            </Box>
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor:
                                                                        colors
                                                                            .redAccent[400],
                                                                    height: '2vh',
                                                                    width: '4rem',
                                                                }}
                                                                onClick={(
                                                                    event,
                                                                ) => {
                                                                    event.stopPropagation();
                                                                    // TODO: Delete Permission
                                                                    console.log(
                                                                        permission,
                                                                        'HI',
                                                                    );
                                                                }}
                                                            >
                                                                <Tooltip title="Delete">
                                                                    <DeleteIcon />
                                                                </Tooltip>
                                                            </Button>
                                                        </Box>
                                                    </ListItemButton>
                                                ),
                                            )}
                                        </List>
                                    ) : (
                                        <CustomNoDataOverlay />
                                    )}

                                    {/* {roleBindings && roleBindings.length > 0 ? (
                                        <DraggableList
                                            items={roleBindings}
                                            onDragEnd={onDragEnd}
                                        />
                                    ) : (
                                        <CustomNoDataOverlay />
                                    )} */}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default bindings;
