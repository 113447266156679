import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Topbar from '@src/views/global/Topbar';
import { SidebarContextProvider } from '@src/global/context/sidebar';
import Sidebar from '@src/views/global/Sidebar';

import { useSelector, useDispatch } from 'react-redux';
import { useGetUserQuery } from '@src/slices/authApiSlice';
import { setDashboardGraphData } from '@src/slices/metricsSlice';
import { useGetDashboardGraphQuery } from '@src/slices/metricsApiSlice';
import Footer from '@src/global/components/Footer';

const DashboardRoute = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { userInfo } = useSelector((state: any) => state.auth);
    const { refetch: refetchUser } = useGetUserQuery({});

    // let getDashboardGraphData = useGetDashboardGraphQuery({});
    // const { dashboardGraphData } = useSelector((state: any) => state.dataTable);

    useEffect(() => {
        void refetchUser()
            .unwrap()
            .then((data: any) => {
                if (data?.message) {
                    if (data.message === 'Not Authenticated') {
                        dispatch({
                            type: 'auth/setCredentials',
                            payload: null,
                        });
                    }
                    if (data.message === 'Authenticated') {
                        if (data.user) {
                            // console.log("Authenticated", data.user);
                            dispatch({
                                type: 'auth/setCredentials',
                                payload: data.user,
                            });
                        }
                    }
                }
            });

        // getDashboardGraphData.refetch().unwrap().then((data: any) => {
        //     console.log(data);
        //     if (data && data.success) {
        //         console.log("Data2", data);
        //         // dispatch({ type: 'metrics/setDashboardGraphData', payload: { data: data.data } });
        //         dispatch(setDashboardGraphData({ data: data.data }));
        //     }
        // });
    }, [location.pathname]);

    // console.log("Cool test" + dashboardGraphData)

    return (
        <>
            <SidebarContextProvider>
                <Sidebar />
            </SidebarContextProvider>

            <main className="content">
                <Topbar />
                <Outlet />
                <Footer />
            </main>
        </>
    );
    // return userInfo ? (
    //     <>
    //         <SidebarContextProvider>
    //             <Sidebar />
    //         </SidebarContextProvider>

    //         <main className="content">
    //             <Topbar />
    //             <Outlet />
    //         </main>
    //     </>
    // ): <Navigate to="/login" replace />;
};

export default DashboardRoute;
