import {
    useEffect,
    useContext,
    useState,
    useRef,
    useMemo,
    forwardRef,
} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    IconButton,
    type TablePaginationProps,
    useTheme,
    Pagination as MuiPagination,
    Paper,
    Avatar,
    Typography,
    Dialog,
    Slide,
    DialogContent,
    DialogActions,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch,
    Grid,
    Divider,
    Collapse,
    CardContent,
    Autocomplete,
    Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import {
    DataGrid,
    GridCheckIcon,
    GridCloseIcon,
    GridExpandMoreIcon,
    GridPagination,
    GridRow,
    GridToolbar,
    gridPageCountSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { type TransitionProps } from '@mui/material/transitions';
import { getColors } from '@src/global/context/theme';

import UndoIcon from '@mui/icons-material/Undo';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import utc from 'dayjs/plugin/utc';

import Header from '@src/global/components/Header';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    useGetBanDataQuery,
    useDeleteBanMutation,
    useCreateBanMutation,
    useUpdateBanMutation,
} from '@src/slices/dataTableApiSlice';
import axios from 'axios';

dayjs.extend(utc);

const customOverlayTemplate = (
    props: any,
    theme: any,
    colors: any,
): JSX.Element => {
    return (
        <Box
            className="MuiDataGrid-overlay"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',

                '& .ant-empty-img-1': {
                    fill:
                        theme.palette.mode === 'light'
                            ? colors.blueAccent[700]
                            : colors.blueAccent[300],
                },
                '& .ant-empty-img-2': {
                    fill:
                        theme.palette.mode === 'light'
                            ? colors.blueAccent[700]
                            : colors.blueAccent[300],
                },
                '& .ant-empty-img-3': {
                    fill:
                        theme.palette.mode === 'light'
                            ? colors.blueAccent[600]
                            : colors.blueAccent[500],
                },
                '& .ant-empty-img-4': {
                    fill:
                        theme.palette.mode === 'light'
                            ? colors.blueAccent[800]
                            : colors.blueAccent[200],
                },
                '& .ant-empty-img-5': {
                    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.2',
                    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
                },
            }}
        >
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g
                        className="ant-empty-img-4"
                        transform="translate(149.65 15.383)"
                    >
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>
            {props}
        </Box>
    );
};

const modalTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const bans = (): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(1);
    const [rowCount, setRowCount] = useState(0);

    const [banSearch, setBanSearch] = useState<any>([] as any[]);
    const [banSearchResults, setBanSearchResults] = useState<any>([] as any[]);

    const [permanentBan, setPermanentBan] = useState(true);
    const [appealableBan, setAppealableBan] = useState(true);
    const [showCreateBanModal, setShowCreateBanModal] = useState(false);
    const [showSearchBans, setShowSearchBans] = useState(false);
    const [showActiveBans, setShowActiveBans] = useState(true);

    const expandedMetrics: number[] = [];

    const useridRef = useRef<any>(null);
    const reasonRef = useRef<any>(null);
    const evidenceRef = useRef<any>(null);
    const blacklistRef = useRef<any>(null);
    const expirationRef = useRef<any>(null);
    // let expirationTimeRef = useRef<any>(null);

    const getBanData = useGetBanDataQuery({ page, active: showActiveBans });
    const { activeBanData, inactiveBanData } = useSelector(
        (state: any) => state.dataTable,
    );

    const [requestDeleteBans] = useDeleteBanMutation();
    const [requestCreateBans] = useCreateBanMutation();
    const [requestUpdateBans] = useUpdateBanMutation();

    useEffect(() => {
        refreshTableData(false);
    }, [location.pathname, page, showActiveBans]);

    // TODO: Implement ban creation
    const createBanModal = (): JSX.Element => {
        return (
            <Dialog
                open={showCreateBanModal}
                onClose={() => {
                    setShowCreateBanModal(false);
                    setPermanentBan(true);
                    setAppealableBan(true);
                }}
                TransitionComponent={modalTransition}
                sx={{
                    ariaLabelledby: 'alert-dialog-title',
                    ariaDescribedby: 'alert-dialog-description',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: colors.blueAccent[600],
                        padding: '18px',
                        marginBottom: '-10px',
                        borderBottomLeftRadius: '14px',
                        borderBottomRightRadius: '14px',
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{ color: colors.primary[100], fontWeight: 'bold' }}
                    >
                        Create Ban
                    </Typography>
                </Box>
                <DialogContent>
                    <Grid
                        sx={{
                            padding: '60px',
                        }}
                        container
                        direction="column"
                        spacing={1}
                    >
                        <Grid item xs={4}>
                            <Typography
                                variant="h4"
                                sx={{ color: colors.primary[200] }}
                            >
                                Please provide the information for the ban you
                                are creating.
                            </Typography>
                            <br />
                            <Divider />
                        </Grid>
                        <Grid item xs={4} container direction="column">
                            <TextField
                                sx={{
                                    mt: '-7px',
                                }}
                                required
                                autoFocus
                                // margin="dense"
                                id="name"
                                name="userid"
                                label="User ID"
                                type="number"
                                color="info"
                                variant="filled"
                                fullWidth
                                helperText="Please provide the user ID of the user you are banning."
                                // onChange={(evnt) => { setCreateBanData({...createBanData, userid: evnt.target.value}) }}
                                inputRef={useridRef}
                            />
                            <TextField
                                required
                                // margin="dense"
                                id="name"
                                name="reason"
                                label="Ban Reason"
                                type="text"
                                color="info"
                                variant="filled"
                                fullWidth
                                helperText="Please provide the reason for the ban."
                                // onChange={(evnt) => { setCreateBanData({...createBanData, reason: evnt.target.value}) }}
                                inputRef={reasonRef}
                            />
                            <TextField
                                required
                                // margin="dense"
                                id="name"
                                name="evidence"
                                label="Ban Evidence"
                                type="text"
                                color="info"
                                variant="filled"
                                fullWidth
                                helperText="Please provide the evidence for the ban."
                                // onChange={(evnt) => { setCreateBanData({...createBanData, evidence: evnt.target.value}) }}
                                inputRef={evidenceRef}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    label="Appealable"
                                    control={
                                        <Switch
                                            defaultChecked
                                            color="warning"
                                            onChange={(evnt, checked) => {
                                                setAppealableBan(checked);
                                            }}
                                            // inputRef={permanentBanRef}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Permanent"
                                    control={
                                        <Switch
                                            defaultChecked
                                            color="error"
                                            onChange={(evnt, checked) => {
                                                setPermanentBan(checked);
                                            }}
                                            // inputRef={permanentBanRef}
                                        />
                                    }
                                />
                                <FormControlLabel
                                    label="Blacklist"
                                    control={
                                        <Switch
                                            color="primary"
                                            // onChange={(evnt, checked) => { setCreateBanData({...createBanData, blacklist: checked}) }}
                                            inputRef={blacklistRef}
                                        />
                                    }
                                />
                            </FormGroup>
                            {!permanentBan ? (
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DateTimePicker
                                        sx={{ mt: '10px' }}
                                        label="Expiration Time"
                                        timezone="system"
                                        inputRef={expirationRef}
                                        defaultValue={dayjs(Date.now())}
                                    />
                                </LocalizationProvider>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: colors.redAccent[400],
                                        height: '2.5rem',
                                        width: '4rem',
                                        mr: '1vw',
                                    }}
                                    onClick={() => {
                                        setShowCreateBanModal(false);
                                        setPermanentBan(true);
                                    }}
                                >
                                    <Typography>Cancel</Typography>
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor:
                                            colors.greenAccent[400],
                                        height: '2.5rem',
                                        width: '4rem',
                                    }}
                                    onClick={() => {
                                        void createBan();
                                    }}
                                >
                                    <Typography>Create</Typography>
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    };

    const createBan = async (): Promise<void> => {
        try {
            const banData = {
                userId: useridRef.current.value,
                reason: reasonRef.current.value,
                evidence: evidenceRef.current.value,
                appealable: appealableBan,
                permanent: permanentBan,
                blacklist: blacklistRef.current.checked,
                expiration: expirationRef.current
                    ? expirationRef.current.value
                    : null,
            };
            if (banData.userId === '' || banData.reason === '') {
                toast.error('Please provide a user id and reason for the ban.');
                return;
            }

            const res = await requestCreateBans(banData).unwrap();
            if (res?.success) {
                toast.success('Successfully Created Ban');
            } else {
                if (res?.message) {
                    toast.error(`Error: ${res.message}`);
                } else {
                    toast.error('Unhandeled error occured while creating bans');
                }
            }
        } catch (e) {
            toast.error('Unhandeled error occured while creating bans');
            console.log(e);
        }

        refreshTableData(false);
        setShowCreateBanModal(false);
        setPermanentBan(true);
    };

    const searchBans = async (): Promise<void> => {
        try {
            const usernameSearch = (banSearch || [])
                .filter((username: string) => {
                    return isNaN(Number(username)) || Number(username) === 0;
                })
                .join(',');
            const userIdSearch = (banSearch || [])
                .filter((userId: string) => {
                    return !isNaN(Number(userId)) && Number(userId) !== 0;
                })
                .join(',');

            const reqUrl =
                usernameSearch.length > 0
                    ? userIdSearch.length > 0
                        ? process.env.REACT_APP_API_URI +
                          `/v1/moderation/bans?${showActiveBans ? 'active&' : ''}reverse&username=` +
                          usernameSearch +
                          '&userId=' +
                          userIdSearch
                        : process.env.REACT_APP_API_URI +
                          `/v1/moderation/bans?${showActiveBans ? 'active&' : ''}reverse&username=` +
                          usernameSearch
                    : userIdSearch.length > 0
                      ? process.env.REACT_APP_API_URI +
                        `/v1/moderation/bans?${showActiveBans ? 'active&' : ''}reverse&userId=` +
                        userIdSearch
                      : null;

            if (reqUrl == null) return;

            void axios
                .get(reqUrl, {
                    withCredentials: true,
                })
                .then((res) => {
                    const _bans = (
                        res
                            ? res.data
                                ? res.data.bans
                                    ? Array.isArray(res.data.bans)
                                        ? res.data.bans
                                        : []
                                    : []
                                : []
                            : []
                    ) as any[];
                    if (_bans.length === 0) {
                        toast.error(
                            'No bans found with the provided search criteria.',
                        );
                        return;
                    }
                    // console.log(_bans);
                    setBanSearchResults(_bans);
                    //   setBans(_bans);
                });
        } catch (err) {
            toast.error('Unhandeled error occured while searching bans');
            console.log(err);
        }
    };

    const deleteSelectedBans = async (data: string[]): Promise<void> => {
        try {
            const res = await requestDeleteBans({ ids: data }).unwrap();
            if (res?.success) {
                toast.success('Successfully Deleted Bans');
                // console.log(res);
                // console.log(data);
                // dispatch(deleteBans(data));
                dispatch({
                    type: 'dataTable/deleteBans',
                    payload: {
                        page,
                        showActiveBans,
                        data,
                    },
                });
                refreshTableData(false);
            } else {
                if (res?.message) {
                    toast.error(`Error: ${res.message}`);
                } else {
                    toast.error('Unhandeled error occured while deleting bans');
                }
            }
        } catch (e) {
            toast.error('Unhandeled error occured while deleting bans');
            console.log(e);
        }
    };

    const updateSelectedBans = async (data: any): Promise<void> => {
        try {
            const res = await requestUpdateBans(data).unwrap();
            if (res?.success) {
                toast.success('Successfully Updated Bans');
                refreshTableData(false);
            } else {
                if (res?.message) {
                    toast.error(`Error: ${res.message}`);
                } else {
                    toast.error('Unhandeled error occured while updating bans');
                }
            }
        } catch (e) {
            toast.error('Unhandeled error occured while updating bans');
            console.log(e);
        }
    };

    const refreshTableData = (doToast: boolean = false): void => {
        if (doToast) {
            toast(
                (t) => (
                    <span
                        onClick={() => {
                            toast.dismiss(t.toastProps.toastId);
                        }}
                    >
                        Successfully reloaded table.
                    </span>
                ),
                { icon: <>✅</>, type: 'success' },
            );
        }
        void getBanData
            .refetch()
            .unwrap()
            .then((banData: any) => {
                if (banData?.success) {
                    setPageSize(Number(banData.pageSize));
                    setRowCount(Number(banData.count));
                    dispatch({
                        type: 'dataTable/setBanData',
                        payload: {
                            page,
                            showActiveBans,
                            data: {
                                rowCount: banData.count,
                                bans: banData.bans,
                            },
                        },
                    });
                }
            });
    };

    const CustomNoRowsOverlay = function (): JSX.Element {
        return customOverlayTemplate(
            <>
                <Box sx={{ mt: 1 }}>No Rows</Box>
                <pre>(rows=&#123;[]&#125;)</pre>
            </>,
            theme,
            colors,
        );
    };

    const CustomNoResultsOverlay = function (): JSX.Element {
        return customOverlayTemplate(
            <Box sx={{ mt: 1 }}>No Results from Filter</Box>,
            theme,
            colors,
        );
    };

    const Pagination = function ({
        page: newPage,
        onPageChange,
        className,
    }: Pick<
        TablePaginationProps,
        'page' | 'onPageChange' | 'className'
    >): JSX.Element {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="primary"
                className={className}
                count={pageCount}
                page={newPage}
                onChange={(event: any, _newPage: any) => {
                    // TODO: Fix this eslint error
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    onPageChange(event, _newPage);
                }}
            />
        );
    };

    const CustomPagination = function (props: any): JSX.Element {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    };

    function customRows(row: any): JSX.Element {
        return (
            <Box>
                <GridRow
                    rowId={row.rowId}
                    selected={row.selected}
                    index={row.index}
                    rowHeight={row.rowHeight}
                    containerWidth={row.containerWidth}
                    firstColumnToRender={row.firstColumnToRender}
                    lastColumnToRender={row.lastColumnToRender}
                    visibleColumns={row.visibleColumns}
                    renderedColumns={row.renderedColumns}
                    cellFocus={row.cellFocus}
                    cellTabIndex={row.cellTabIndex}
                    editRowsState={row.editRowsState}
                    position={row.position}
                    row={row.row}
                ></GridRow>
                <Collapse
                    sx={{ backgroundColor: colors.primary[700] }}
                    in={expandedMetrics.includes(row.row._id as number)}
                    timeout={0}
                    unmountOnExit
                >
                    <CardContent>
                        <Paper
                            sx={{
                                width: '40%',
                                p: '5px',
                                borderRadius: '4px',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                mt: '7px',
                                mb: '7px',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    color={colors.greenAccent[300]}
                                    variant="h4"
                                >
                                    Reason
                                </Typography>
                                <Typography variant="body2">
                                    {row.row.reason
                                        ? row.row.reason
                                        : 'No reason provided.'}
                                </Typography>
                            </CardContent>
                        </Paper>
                        <Paper
                            sx={{
                                width: '40%',
                                p: '5px',
                                borderRadius: '4px',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                mt: '7px',
                                mb: '7px',
                            }}
                        >
                            <CardContent>
                                <Typography
                                    color={colors.greenAccent[300]}
                                    variant="h4"
                                >
                                    Evidence
                                </Typography>
                                <Typography variant="body2">
                                    {row.row.evidence
                                        ? row.row.evidence
                                        : 'No evidence provided.'}
                                </Typography>
                            </CardContent>
                        </Paper>
                        {/* <Typography paragraph>
                        {row.row.reason}
                    </Typography> */}
                    </CardContent>
                </Collapse>
            </Box>
        );
    }

    const columns = [
        {
            field: 'user_id',
            headerName: 'User',
            flex: 0.75,
            renderCell: ({ row }: any) => {
                return (
                    <Paper
                        elevation={6}
                        sx={{
                            width: '80%',
                            p: '5px',
                            borderRadius: '4px',
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            mt: '7px',
                            mb: '7px',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                p: '5px',
                                borderRadius: '4px',
                                display: 'flex',
                                gap: '10px',
                            }}
                        >
                            <Avatar
                                src={
                                    row.thumbnail
                                        ? row.thumbnail.thumbnail
                                            ? row.thumbnail.thumbnail.value
                                            : ''
                                        : ''
                                }
                                variant="rounded"
                                sx={{
                                    minWidth: 52,
                                    minHeight: 64,
                                    border: `3px solid ${colors.primary[700]}`,
                                    backgroundColor: colors.primary[700],
                                }}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    gap: '5px',
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{ color: colors.primary[100] }}
                                >
                                    {row.user_name
                                        ? row.user_name
                                        : 'Default Username'}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{ color: colors.primary[200] }}
                                >
                                    {row.user_id
                                        ? row.user_id
                                        : 'Default UserId'}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                );
            },
        },
        {
            field: 'mod_name',
            headerName: 'Banned By',
            flex: 0.5,
            cellClassName: 'name-column--cell',
        },
        {
            field: 'is_active',
            headerName: 'Active',
            type: 'boolean',
            flex: 0.25,
            // valueGetter: ({ row }: any) => (row.permanent && !row.appealed) || (!row.permanent && row.date_expires > Date.now())
            renderCell: ({ row }: any) => {
                return ((row.permanent || row.blacklist) && !row.appealed) ||
                    (!row.permanent &&
                        Date.parse(String(row.date_expires)) > Date.now()) ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                );
            },
        },
        {
            field: 'permanent',
            headerName: 'Permanent',
            type: 'boolean',
            flex: 0.25,
            renderCell: (params: any) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                ),
        },
        {
            field: 'blacklist',
            headerName: 'Blacklisted',
            type: 'boolean',
            flex: 0.25,
            renderCell: (params: any) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                ),
        },
        {
            field: 'appealed',
            headerName: 'Appealed',
            type: 'boolean',
            flex: 0.25,
            renderCell: (params: any) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                ),
        },
        {
            field: 'revoked',
            headerName: 'Revoked',
            type: 'boolean',
            flex: 0.25,
            renderCell: (params: any) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: colors.greenAccent[500],
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: colors.redAccent[300],
                        }}
                    />
                ),
        },
        {
            field: 'date_created',
            headerName: 'Created',
            type: 'dateTime',
            flex: 0.5,
            valueGetter: ({ value }: any) => value && new Date(String(value)),
            renderCell: ({ value }: any) =>
                value && (
                    <Paper
                        sx={{
                            padding: '6px',
                            fontWeight: 'bold',
                            borderRadius: '6px',
                            backgroundColor: colors.primary[400],
                            color: colors.primary[100],
                        }}
                    >
                        {' '}
                        {new Date(String(value)).toLocaleTimeString() +
                            ' ' +
                            new Date(String(value)).toLocaleDateString()}{' '}
                    </Paper>
                ),
        },
        {
            field: 'date_expires',
            headerName: 'Expires',
            type: 'dateTime',
            flex: 0.5,
            valueGetter: ({ value }: any) =>
                value && new Date(String(value)).getTime() > 0
                    ? new Date(String(value))
                    : 'PERMANENT',
            renderCell: ({ value }: any) =>
                value && new Date(String(value)).getTime() > 0 ? (
                    <Paper
                        sx={{
                            padding: '6px',
                            fontWeight: 'bold',
                            borderRadius: '6px',
                            backgroundColor: colors.primary[400],
                            color: colors.primary[100],
                        }}
                    >
                        {' '}
                        {new Date(String(value)).toLocaleTimeString() +
                            ' ' +
                            new Date(String(value)).toLocaleDateString()}{' '}
                    </Paper>
                ) : (
                    <Paper
                        sx={{
                            padding: '6px',
                            fontWeight: 'bold',
                            borderRadius: '6px',
                            backgroundColor: colors.primary[400],
                            color: colors.redAccent[300],
                        }}
                    >
                        PERMANENT
                    </Paper>
                ),
        },
        {
            field: 'controls',
            headerName: 'Controls',
            flex: 1,
            renderCell: ({ row }: any) => {
                const deleteRequest = async (): Promise<void> => {
                    await Swal.fire({
                        title: 'Delete Ban',
                        text: "You're about to delete a ban! Are you sure you want to do this?",
                        showConfirmButton: true,
                        showDenyButton: true,
                        allowEscapeKey: true,
                        allowEnterKey: true,
                        icon: 'warning',
                    }).then(async (value) => {
                        if (value.isDenied) {
                            void Swal.fire({
                                title: 'Delete Ban',
                                text: 'Successfully cancelled.',
                                allowEnterKey: true,
                                icon: 'error',
                            });
                        }
                        if (value.isConfirmed) {
                            await deleteSelectedBans([String(row._id)]);
                        }
                    });
                };

                const appealRequest = (valueToSet: boolean): void => {
                    void Swal.fire({
                        title: valueToSet ? 'Appeal Ban' : 'Unappeal Ban',
                        text: `You're about to set a ban as ${valueToSet ? 'appealed' : 'unappealed'}! Are you sure you want to do this?`,
                        showConfirmButton: true,
                        showDenyButton: true,
                        allowEscapeKey: true,
                        allowEnterKey: true,
                        icon: 'warning',
                    }).then(async (value) => {
                        if (value.isDenied) {
                            void Swal.fire({
                                title: valueToSet
                                    ? 'Appeal Ban'
                                    : 'Unappeal Ban',
                                text: 'Successfully cancelled.',
                                allowEnterKey: true,
                                icon: 'error',
                            });
                        }
                        if (value.isConfirmed) {
                            void updateSelectedBans({
                                id: row._id,
                                appealed: valueToSet,
                            });
                        }
                    });
                };

                const revokeRequest = (valueToSet: boolean): void => {
                    void Swal.fire({
                        title: valueToSet ? 'Revoke Ban' : 'Revoke Ban',
                        text: `You're about to set a ban as ${valueToSet ? 'revoked' : 'not revoked'}! Are you sure you want to do this?`,
                        showConfirmButton: true,
                        showDenyButton: true,
                        allowEscapeKey: true,
                        allowEnterKey: true,
                        icon: 'warning',
                    }).then(async (value) => {
                        if (value.isDenied) {
                            void Swal.fire({
                                title: valueToSet
                                    ? 'Revoke Ban'
                                    : 'Reinstate Ban',
                                text: 'Successfully cancelled.',
                                allowEnterKey: true,
                                icon: 'error',
                            });
                        }
                        if (value.isConfirmed) {
                            void updateSelectedBans({
                                id: row._id,
                                revoked: valueToSet,
                            });
                        }
                    });
                };

                return (
                    <Box
                        sx={{
                            width: '60%',
                            p: '5px',
                            display: 'flex',
                            borderRadius: '4px',
                        }}
                    >
                        <IconButton
                            onClick={() =>
                                expandedMetrics.includes(row._id as number)
                                    ? expandedMetrics.splice(
                                          expandedMetrics.indexOf(
                                              row._id as number,
                                          ),
                                          1,
                                      )
                                    : expandedMetrics.push(row._id as number)
                            }
                            sx={{
                                transform: expandedMetrics.includes(
                                    row._id as number,
                                )
                                    ? 'rotate(180deg)'
                                    : 'rotate(0deg)',
                                transition: 'transform 0.2s',
                                ariaExpanded: expandedMetrics
                                    .includes(row._id as number)
                                    .toString(),
                                ariaLabel: 'show more',
                            }}
                        >
                            <GridExpandMoreIcon />
                        </IconButton>
                        <Button
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={deleteRequest}
                            color="error"
                            variant="outlined"
                        >
                            <DeleteIcon sx={{ color: colors.redAccent[400] }} />
                        </Button>
                        <Button
                            onClick={
                                showActiveBans
                                    ? () => {
                                          appealRequest(true);
                                      }
                                    : () => {
                                          appealRequest(false);
                                      }
                            }
                            sx={{
                                maxHeight: '2.4rem',
                                minWidth: '7rem',
                                ml: '4px',
                                backgroundColor: colors.greenAccent[700],
                                whiteSpace: 'initial',
                                wordBreak: 'break-word',
                            }}
                            variant="contained"
                            startIcon={showActiveBans ? '' : <UndoIcon />}
                        >
                            <Typography>Appeal</Typography>
                        </Button>
                        <Button
                            onClick={
                                showActiveBans
                                    ? () => {
                                          revokeRequest(true);
                                      }
                                    : () => {
                                          revokeRequest(false);
                                      }
                            }
                            sx={{
                                maxHeight: '2.4rem',
                                minWidth: '7rem',
                                ml: '4px',
                                whiteSpace: 'initial',
                                wordBreak: 'break-word',
                            }}
                            variant="contained"
                            startIcon={showActiveBans ? '' : <UndoIcon />}
                        >
                            <Typography>Revoke</Typography>
                        </Button>
                    </Box>
                );
            },
        },
    ];

    return (
        <>
            {createBanModal()}
            <Box m="20px">
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Header title="Ban Controls" subtitle="Banning the n00bs" />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            ml: 'auto',
                            mr: '2vw',
                        }}
                    >
                        <Grid container sx={{ transform: 'scale(0.9)' }}>
                            <Grid
                                item
                                sx={{ display: 'flex', maxWidth: '32rem' }}
                            >
                                <Autocomplete
                                    sx={{
                                        width: '100%',
                                        minWidth: '18rem',
                                        transform: showSearchBans
                                            ? 'scale(1)'
                                            : 'scale(0)',
                                        transition: 'transform 0.5s',
                                    }}
                                    multiple
                                    id="tags-filled"
                                    options={(showActiveBans
                                        ? activeBanData
                                            ? activeBanData.bans ?? []
                                            : []
                                        : inactiveBanData
                                          ? inactiveBanData.bans ?? []
                                          : []
                                    )
                                        .map((option: any) =>
                                            option.user_name
                                                ? option.user_name
                                                : 'Default Username',
                                        )
                                        .filter(
                                            (
                                                option: any,
                                                index: any,
                                                self: any,
                                            ) => self.indexOf(option) === index,
                                        )}
                                    defaultValue={[]}
                                    freeSolo
                                    renderTags={(
                                        value: readonly string[],
                                        getTagProps,
                                    ) =>
                                        value.map(
                                            (option: string, index: number) => (
                                                // eslint-disable-next-line react/jsx-key
                                                <Chip
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                />
                                            ),
                                        )
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="filled"
                                            label="Search Usernames (or ids)"
                                            placeholder="Usernames"
                                            sx={{ textAlign: 'center' }}
                                            // inputRef={searchRef}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        setBanSearch(value);
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                alignItems="stretch"
                                style={{ display: 'flex' }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor:
                                            Array.isArray(banSearch) &&
                                            banSearch.length > 0
                                                ? colors.greenAccent[400]
                                                : colors.blueAccent[400],
                                        // height: "3vh",
                                        width: '4rem',
                                    }}
                                    // disabled
                                    onClick={() => {
                                        if (
                                            Array.isArray(banSearch) &&
                                            banSearch.length > 0
                                        ) {
                                            void searchBans();
                                        } else {
                                            setShowSearchBans(!showSearchBans);
                                        }
                                        if (showSearchBans) {
                                            setBanSearchResults([]);
                                        }
                                    }}
                                >
                                    <SearchIcon />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor:
                                            colors.greenAccent[600],
                                        height: '100%',
                                        width: '8rem',
                                        ml: '1vw',
                                        mr: '2vw',
                                    }}
                                    onClick={() => {
                                        setShowCreateBanModal(true);
                                    }}
                                >
                                    Create Ban
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        height: '6vh',
                        backgroundColor: colors.primary[400],
                        borderRadius: '8px',
                        '& button:hover': {
                            backgroundColor: colors.blueAccent[400],
                        },
                    }}
                >
                    <ButtonGroup
                        variant="contained"
                        fullWidth
                        sx={{
                            ariaLabel: 'button group',
                            m: 2,
                            width: '70vw',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: showActiveBans
                                    ? colors.blueAccent[500]
                                    : '',
                            }}
                            onClick={() => {
                                setShowActiveBans(true);
                            }}
                        >
                            Active Bans
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: !showActiveBans
                                    ? colors.blueAccent[500]
                                    : '',
                            }}
                            onClick={() => {
                                setShowActiveBans(false);
                            }}
                        >
                            Inactive Bans
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box
                    m="10px 0 0 0"
                    height="70vh"
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            lineHeight: 'unset !important',
                            maxHeight: 'none !important',
                            borderBottom: 'none',
                            zIndex: 1,
                        },
                        '& .name-column--cell': {
                            color: colors.greenAccent[300],
                        },
                        '& .MuiDataGrid-row': {
                            maxHeight: 'none !important',
                            lineHeight: 'unset !important',
                            '&:nth-of-type(2n)': {
                                backgroundColor: colors.blueAccent[800],
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: colors.blueAccent[600],
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            backgroundColor: colors.primary[400],
                            zIndex: showActiveBans
                                ? activeBanData
                                    ? activeBanData.bans != null
                                        ? activeBanData.bans.length > 0
                                            ? 1
                                            : -1
                                        : -1
                                    : -1
                                : inactiveBanData
                                  ? inactiveBanData.bans != null
                                      ? inactiveBanData.bans.length > 0
                                          ? 1
                                          : -1
                                      : -1
                                  : -1,
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderTop: 'none',
                            backgroundColor: colors.blueAccent[600],
                        },
                        '& .MuiCheckbox-root': {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                            color: `${colors.primary[100]} !important`,
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text:hover':
                            {
                                color: `${colors.primary[200]} !important`,
                            },
                        '& .date-value': {
                            backgroundColor: colors.primary[400],
                            color: colors.primary[100],
                            borderRadius: '6px',
                            padding: '6px',
                            fontWeight: 'bold',
                        },
                    }}
                >
                    <DataGrid
                        checkboxSelection
                        disableVirtualization
                        rows={
                            banSearchResults && banSearchResults.length > 0
                                ? banSearchResults
                                : showActiveBans
                                  ? activeBanData
                                      ? activeBanData.bans ?? []
                                      : []
                                  : inactiveBanData
                                    ? inactiveBanData.bans ?? []
                                    : []
                        }
                        columns={columns as any}
                        getRowId={(row) => {
                            if (row._id) {
                                return row._id;
                            }
                            return 'RIP';
                        }}
                        components={{
                            Toolbar: GridToolbar,
                            NoRowsOverlay: CustomNoRowsOverlay,
                            NoResultsOverlay: CustomNoResultsOverlay,
                            Pagination: CustomPagination,
                            Row: customRows,
                        }}
                        columnVisibilityModel={
                            showActiveBans
                                ? { revoked: false, appealed: false }
                                : {}
                        }
                        rowCount={rowCount ? rowCount ?? 0 : 0}
                        pageSize={pageSize}
                        rowsPerPageOptions={[pageSize]}
                        onPageChange={(params) => {
                            // TODO:
                            setPage(params);
                        }}
                        paginationMode="server"
                    />

                    {/* Bulk Controls */}
                    {/* <Box
                        sx={{
                            marginTop: "1vh",
                            width: "6%",
                            backgroundColor: colors.primary[400],
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton disabled color="secondary" aria-label="download picture" component="label">
                            <DownloadIcon />
                        </IconButton>
                        <IconButton disabled color="error" aria-label="delete picture" component="label">
                            <DeleteIcon />
                        </IconButton>
                    </Box> */}
                </Box>
            </Box>
        </>
    );
};

export default bans;
